.resume-box {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-top: 0rem;
}

.link-color {
  color: #00c2b2;
}

.resume-bg-black {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
}

.resume-black-text {
  text-align: center;
  font-family: 'Lato', serif;

  line-height: 1;

  h3 {
    font-size: 2.5rem;
    padding: 1rem 0rem;
    color: #00fdd7;
  }

  h4 {
    color: white;
    font-size: 1.5rem;
    padding: 1rem;
  }
  p {
    color: white;
    padding: 0rem 1rem 0rem;
  }

  hr {
    width: 40%;
    margin: auto;
  }
}

.resume-address {
  padding: 1rem 0rem;
}

.resume-contact {
  padding-top: 1rem;
}

.resume-bg-white {
  background-color: white;
}

.links {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.resume-text-format {
  padding-left: 1.5rem;
  padding-top: 2rem;
  font-family: 'Lato', sans-serif;

  h3,
  h4,
  h5 {
    color: black;
  }

  hr {
    width: 95%;
  }

  h3 {
    font-weight: bold;
    padding-bottom: 0.5rem;
    font-size: 1.3rem;
  }
  h4 {
    padding-top: 0.5rem;
    font-size: 1rem;
  }

  p,
  h5 {
    padding: 0.3rem 1.3rem 0.3rem 0rem;
  }

  p {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.8rem;
  }
}

.resume-text-titles {
  color: black;
}

.resume-sections {
  padding-bottom: 0.5rem;
}

@media screen and (max-width: 1200px) {
  .resume-box {
    width: 90%;
  }
  .resume-text-format {
    p {
      font-size: 1.2rem;
    }
    h3 {
      font-size: 1.5rem;
    }
    h4 {
      font-size: 1.3rem;
    }
    h5 {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 750px) {
  .resume-box {
    padding-top: 0rem;
    flex-direction: column;
    width: 100%;
  }
  .resume-bg-black {
    padding-top: 2rem;
    height: 280px;

    h3 {
      padding: 2rem 0 1rem;
    }
  }

  .resume-black-text {
    display: flex;
    justify-content: center;
    line-height: 0.5;
    hr {
      display: none;
    }
  }

  .resume-address {
    padding: 0rem;
  }

  .contact-box {
    text-align: left;
    padding-right: 0rem;
    padding-bottom: 1rem;
  }

  .resume-contact {
    display: flex;
    padding-top: 0rem;
    p {
      padding: 0.2rem;
    }
  }

  .links {
    padding-top: 1rem;
  }
}

@media screen and (max-width: 460px) {
  .resume-box {
    width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .resume-bg-black {
    padding-top: 7rem;
  }
}
