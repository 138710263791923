.projects-layout {
  min-height: 100vh;
  color: white;
  overflow: hidden;
  padding: 2rem 10rem;
}

.projects-title {
  padding: 3rem 0rem 1rem;
  color: white;
}

.frame {
  width: 800px;
}

.project-items {
  margin-bottom: 5rem;
}

.social-pic {
  width: 100%;
}

@media screen and (max-width: 1600px) {
  .iframe {
    width: 1000px;
  }
}

@media screen and (max-width: 1250px) {
  .iframe {
    width: 800px;
  }
}

@media screen and (max-width: 1100px) {
  .iframe {
    width: 100%;
  }
}

@media screen and (max-width: 880px) {
  .projects-layout {
    padding: 7rem 1rem;
  }
  .iframe {
    height: 700px;
  }

  .thumbnail {
    width: 100%;
  }
}
