nav {
  position: absolute;
  height: 100px;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  color: white;
  z-index: 1;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  background: #191919;
  box-shadow: 2px 2px 50px rgb(46, 46, 46);
}

.nav-button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: fixed;
  top: 30px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
  color: white;
  text-decoration: none;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* gallery */

.gallery {
  padding: 10px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.thumbnails {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.thumbnail {
  flex: 1 0 33%;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* .frame {
  overflow: hidden;
} */

.thumbnail img {
  width: 100%;
  height: 100%;
}

.single {
  overflow: hidden;
  height: 100vh;
}

.single img {
  max-width: 100%;
  max-height: 100vh;
}

.back {
  position: fixed;
  top: 50px;
  right: 50px;
  font-size: 54px;
  z-index: 1;
}

.back a {
  text-decoration: none;
}

@media screen and (min-width: 1200px) {
  h1 {
    font-size: 60px;
    bottom: -260px;
  }
}

.link-style {
  color: 'inherit';
  text-decoration: 'none';
}
